import config from "@/config/config.json";
const publications = null;
// const agencyContent = null;
// const hubContent = null;

let myApp = {
  config,
  // agencyContent,
  // hubContent,
  publications,
};

export { myApp };
